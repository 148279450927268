.footer {
  background-image: url('./Assets//Images/footer_bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

#header {
  background-image: url("./Assets/Images/bg.jpg");
  height: 380px;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

#header h3 {
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  font-size: 58px;
}

@media screen and (max-width: 480px) {
  #header {
    /* background-image: url("./Assets/Images/about_bg.png"); */
    height: 188px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  #header h3 {
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
    font-size: 36px;
  }

  #header .breadcrumbs span {
    font-size: 14px;
  }
}

/*  */


.single-view-header {
  background-image: url("./Assets/Images/bg.jpg");
  height: 320px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.single-view-header h3 {
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  font-size: 48px;
}

@media screen and (max-width: 480px) {
  .single-view-header {
    background-image: url("./Assets/Images/bg.jpg");
    height: 188px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .single-view-header h3 {
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
    font-size: 26px;
  }

  .single-view-header .breadcrumbs span {
    font-size: 10px;
  }
}

/* Option CSS */

#project-tab li {
  margin: 0 15px;
  -webkit-transition: .5s;
  transition: .5s;
}

#project-tab .btn-outline-primary.active,
#project-tab .btn-outline-primary:hover {
  color: #fff;
  background-color: #1e396c;
  border-color: #1e396c;
}

#project-tab li .btn {
  width: 130px;
  height: 135px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#project-tab .btn-outline-primary {
  color: #1e396c;
  border-color: #1e396c;
}

#project-tab li i {
  line-height: 1.4;
  margin-bottom: -3px;
  font-size: 26px;
  margin: 0 0 6px 0;
}

/* Property Card */

.pro_list {
  transition: box-shadow .2s;
  border-radius: 7px;
  overflow: hidden;
}

#projlistId .pro_list .pro_img img {
  height: 100%;
  object-fit: cover;
}

.rounded-start {
  border-bottom-left-radius: .25rem !important;
  border-top-left-radius: .25rem !important;
}

.pro_list .pro_img span {
  position: absolute;
  top: 22px;
  left: 0;
  color: #fff;
  background-color: #1e396c;
  padding: 5px 20px;
  background-image: linear-gradient(to right, #1e396c, #1e396c);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 50px;
  font-size: 13px;
}

.pro_list .price {
  font-weight: 600;
  color: #1e396c;
  margin-bottom: 10px;
}

.pro_list .card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.pro_list .card-body {
  padding: 10px 12px;
}

.pro_list .location {
  line-height: 1;
  margin: 5px 0 10px;
  font-size: 14px;
}

.pro_list .company__logo {
  max-width: 125px;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.accordion-item:first-of-type {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.accordion-header {
  margin-bottom: 0;
}

.pro_list .summry ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.head ul {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
  padding: 10px 0;
}

.head li {
  list-style: none;
  border: #e7e7e7 solid 1px;
  border-radius: 20px;
  padding: 10px 18px;
  background: #eee;
  margin-right: 5px;
  display: flex;
  align-items: center;
  width: 100%;
}

.pro_list .summry ul li i {
  float: left;
  color: #1e396c;
  font-size: 16px;
  margin-right: 10px;
  line-height: .9;

}

.pro_list .summry ul li div {
  float: left;
  line-height: 1;
}

.pro_list .summry ul li div span {
  text-transform: uppercase;
  color: #606060;
  line-height: 1;
  font-size: 10px;
  letter-spacing: .5px;
  margin-bottom: 2px;
  display: block;
  font-weight: 400;
}

.pro_list .summry ul li div p {
  color: #303030;
  font-weight: 600;
  line-height: 1;
  font-size: 11px;
}

.btns {
  margin: 12px 0;
}

.btns .probtn {
  background-color: #1e396c;
  color: white;
  padding: 6px 16px;
  border-radius: 50px;
  font-size: 14px;
  margin-right: 14px;
}

/* Developer CSS */

.developer-card {
  border: 1px solid #1e396c;
  border-radius: 12px;
  padding: 16px;
  text-decoration: none !important;
}

.developer-card:hover {
  background-color: #1e396c48;
  border: 1px solid #1e396c5e;
  border-radius: 12px;
  padding: 16px;
  transition: 0.4s;
  transition-timing-function: ease-in-out
}

.community-card {
  border: 1px solid #1e396c;
  border-radius: 12px;
  text-decoration: none !important;
  padding: 8px;
}

.community-card .media {
  overflow: hidden;
  border-radius: 12px;
}

.community-card:hover img {
  transform: scale(1.1);
  transition: 0.6s;
}

.community-card p.card-text {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* PRO CARD */

.single-featured-item .featured-img {
  position: relative;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.single-featured-item .featured-img img {
  max-height: 220px;
  width: 100%;
  object-fit: cover;
}

.single-featured-item .featured-img span {
  position: absolute;
  bottom: 20px;
  left: 0;
  color: #fff;
  padding: 5px 20px;
  background-image: linear-gradient(to right, #1e396c, #102449);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 50px;
}

.single-featured-item .featured-content {
  box-shadow: 0 1px 8px 0 #ccc;
  padding: 30px 17px 17px;
  border-radius: 10px;
  margin-top: -38px;
  background: #fff;
}

.single-featured-item .featured-content h3 {
  font-size: 17px !important;
  margin-top: 7px;
}

.single-featured-item .featured-content p {
  position: relative;
  padding-left: 22px;
  margin-bottom: 0;
}

.single-featured-item {
  margin-bottom: 30px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  position: relative;
}

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.single-featured-item .featured-content p i {
  position: absolute;
  top: 0;
  left: 0;
  color: #1e396c;
}

.single-featured-item .featured-content ul {
  padding-left: 0;
  margin-bottom: 5px;
  padding-bottom: 5px;
  list-style-type: none;
  border-bottom: 1px solid #e5e5e5;
}

.single-featured-item .featured-content ul li i {
  color: #1e396c;
  font-size: 18px;
  position: relative;
  top: 3px;
  padding-right: 6px;
}

.no-anchor, .no-anchor:hover {
  color: inherit;
  text-decoration: none;
}

.commu_feaure figure {
  width: 80px;
  height: 80px;
}

.commu_feaure figure+h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.commu_feaure figure+h6+p {
  min-height: 38px;
}

.pro_list .card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.pro_list p.card-text {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.widget-sidebar .sidebar-widget {
  padding: 20px;
}

.widget-sidebar .sidebar-widget h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.widget-sidebar .sidebar-widget h3 {
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 8px;
  font-weight: 700;
  color: #333;
  border-bottom: 1px solid rgb(0 0 0 / .15);
}

.filter-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.filter-form .form-group .form-control {
  background-color: #fff;
  border-color: #cdd0d4;
}

.d-grid {
  display: grid !important;
} 

.default-btn {
  color: #fff;
  transition: .5s;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 5px;
  padding: 12px 10px;
  background-color: #1e396c;
}

.default-btn:hover {
  color: #fff;
  background-color: #b9933f;
}

/*  */

